.header_main {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    padding: 2px 145px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.header_base {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.h_responsive_nav {
    display: none;
    padding: 10px;
    width: 20%;
}

.h_responsive_nav_icon {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: #0f172A;
}

.h_logo_cont {
    display: flex;
    justify-content: left;
    width: 20%;
}

.h_logo {
    width: 170px;
    cursor: pointer;
}

.h_nav_main {
    display: block;
    width: 65%;
}

.h_nav_cont {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.h_nav_comp {
    display: flex;
    flex-wrap: wrap;
}

.h_nav_elem {
    padding: 10px 20px;
    background-color: transparent;
    cursor: pointer;
}

.h_nav_elem:hover {
    background-color: #F1F5F9;
    border-radius: 20px;
}

.h_opt_cont {
    display: flex;
    width: 15%;
    justify-content: right;
}

.h_opts {
    display: flex;
    gap: .8vw;
}

.search_page_header {
    background-color: #F0F9FF;
    height: 100px;
}

.h_search_base {
    display: block;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
}

.h_search_cont {
    position: relative;
    display: flex;
    justify-content: center;
}

.page_search_cont {
    position: relative;
    display: flex;
    justify-content: center;
}

.h_search_bar {
    display: flex;
    align-items: center;
    background-color: #F8FAFC;
    width: 600px;
    border-radius: 8px;
    padding: 12px 20px;
}

.h_search_bar2 {
    display: flex;
    align-items: center;
    background-color: #F8FAFC;
    width: 100%;
    margin-top: 12px;
    border-radius: 8px;
    padding: 8px 20px;
}

.page_search_bar {
    display: flex;
    position: absolute;
    top: -40px;
    align-items: center;
    background-color: #fff;
    width: 600px;
    margin-top: 12px;
    border-radius: 30px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 8px 20px;
}

.h_baricon_cont {
    display: flex;
    justify-content: center;
    width: 5%;
}

.h_baricon_cont2 {
    display: flex;
    justify-content: center;
    width: 8%;
}

.h_input_cont {
    width: 90%;
}

.h_input_cont2 {
    width: 80%;
}

.h_closeicon_cont {
    display: flex;
    justify-content: right;
    width: 5%;
}

.h_closeicon_cont2 {
    display: flex;
    justify-content: right;
    width: 12%;
}

.h_search_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0f172A;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.h_search_icon {
    color: #ffffff;

}

.h_input_field {
    border: none;
    outline: none;
    font-size: medium;
    font: inherit;
    width: 100%;
    padding: 8px;
    margin-left: 6px;
    padding-right: 12px;
    box-sizing: inherit;
    background-color: transparent;
}

.h_user_base {
    display: block;
    position: relative;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
}

.h_cart_base {
    display: block;
    position: relative;
    padding: 10px;
    background-color: transparent;
    cursor: pointer;
}

.h_opt_icon_base {
    position: relative;
}

.h_opt_icons {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: #0f172A;
}

.h_opt_counter {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #0EA5E9;
    color: #ffffff;
    border-radius: 50%;
    font-size: 10px;
    padding: 0px 4px;
}

.h_opt_icons_2 {
    display: block;
    font-size: 24px;
    color: #0f172A;
}

.h_search_base:hover {
    background-color: #F1F5F9;
    border-radius: 20px;
}

.h_user_base:hover {
    background-color: #F1F5F9;
    border-radius: 20px;
}

.h_cart_base:hover {
    background-color: #F1F5F9;
    border-radius: 20px;
}

.overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay.hide {
    display: block;
    z-index: 0;
    animation: fadeOut 0.4s;
    -webkit-animation: fadeOut 0.4s;
    -moz-animation: fadeOut 0.4s;
    -o-animation: fadeOut 0.4s;
    -ms-animation: fadeOut 0.4s;
    opacity: 0;
}

.overlay.show {
    display: block;
    opacity: 1;
    z-index: 5;
    animation: fadeIn 0.4s;
    -webkit-animation: fadeIn 0.4s;
    -moz-animation: fadeIn 0.4s;
    -o-animation: fadeIn 0.4s;
    -ms-animation: fadeIn 0.4s;
}


.r_menu_palette {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    border-left: 1px solid #C1D8EE;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: auto;
}

.r_menu_palette.show {
    display: block;
    width: 450px;
    animation: showPalette 0.1s;
    -webkit-animation: showPalette 0.1s;
    -moz-animation: showPalette 0.1s;
    -o-animation: showPalette 0.1s;
    -ms-animation: showPalette 0.1s;
}

.r_menu_palette.show_full {
    display: block;
    width: 100%;
    animation: showPalette 0.1s;
    -webkit-animation: showPalette 0.1s;
    -moz-animation: showPalette 0.1s;
    -o-animation: showPalette 0.1s;
    -ms-animation: showPalette 0.1s;
}

.r_menu_palette.hide {
    animation: hidePalette 0.4s;
    -webkit-animation: hidePalette 0.4s;
    -moz-animation: hidePalette 0.4s;
    -o-animation: hidePalette 0.4s;
    -ms-animation: hidePalette 0.4s;
}

.r_menu_header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 18px;
    border-bottom: 1px solid #e7e7e7;
}

.rmenu_logo_cont {
    display: flex;
    justify-content: left;
}

.rmenu_close_cont {
    display: flex;
    justify-content: right;
}

.brand_line {
    text-align: justify;
}

.rmenu_social_icons {
    display: flex;
    align-items: center;
}

.icon_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a1a1a1;
    border-radius: 50%;
    cursor: pointer;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 6px;
    color: #0f172A;
    border-color: #0f172A;
}

.icon_cont:hover {
    color: #fff;
    background-color: #0f172A;
}

.facebook_icon {
    font-size: 24px;
}

.instagram_icon {
    font-size: 28px;
}

.tiktok_icon {
    font-size: 22px;
}

.resp_items_base {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.resp_menu_item {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px;
    margin-left: 9px;
    margin-right: 9px;
    border-radius: 8px;
    cursor: pointer;
}

.resp_menu_item:hover {
    background-color: #F1F5F9;
}

@media screen and (min-width: 1600px) {
    .header_main {
        display: flex;
        justify-content: center;
    }

    .header_base {
        width: 1115px;
    }
}


@media screen and (max-width:1200px) {
    .header_main {
        padding: 2px 50px;
    }
}

@media screen and (max-width:1000px) {
    .h_nav_main {
        display: none;
    }

    .h_search_base {
        display: none;
    }

    .h_responsive_nav {
        display: block;
    }

    .h_logo_cont {
        width: 60%;
        justify-content: center;
    }
}

@media screen and (max-width:800px) {  
    .page_search_bar {
        width: 100%;
    }
}

@media screen and (max-width:560px) {
    .header_main {
        padding: 20px 8px;
    }

    .h_logo {
        width: 124px;
    }

    .h_opt_icons {
        font-size: 20px;
    }

    .h_opts {
        gap: 3px;
    }

    .h_user_base {
        padding: 5px;
    }

    .h_cart_base {
        padding: 5px;
    }

    .h_responsive_nav_icon {
        font-size: 20px;
    }

    .brand_line {
        font-size: 12px;
    }

    .icon_cont {
        max-width: 30px;
        max-height: 30px;
        min-width: 30px;
        min-height: 30px;
    }

    .facebook_icon {
        font-size: 18px;
    }

    .instagram_icon {
        font-size: 20px;
    }

    .tiktok_icon {
        font-size: 16px;
    }

    .h_search_bar2 {
        padding: 3px 20px;
    }

    .h_opt_icons_2 {
        font-size: 20px;
    }
}

@media screen and (max-width:430px) {  
    .h_search_btn {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width:340px) {
    .h_baricon_cont2 {
        width: 12%;
    }

    .h_closeicon_cont2 {
        width: 16%;
    }

    .h_input_cont2 {
        width: 72%;
    }
}

@keyframes hidePalette {
    0% {
        width: 25%;
    }

    100% {
        width: 0%;
    }
}

@keyframes showPalette {
    0% {
        width: 0%;
    }

    100% {
        width: 25%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0%;
        display: none;
    }

    to {
        opacity: 100%;
        display: block;
    }
}

@keyframes fadeOut {
    from {
        opacity: 100%;
        z-index: 5;
    }

    to {
        opacity: 0%;
        z-index: 5;
    }
}