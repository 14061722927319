.contact_base {
    min-height: 80vh;
    padding-top: 80px;
    padding-bottom: 100px;
}

.contact_cont {
    display: block;
    /* background-color: blueviolet; */
}

.contact_heading {
    font-size: 46px;
    text-align: center;
    padding-bottom: 60px;
    font-weight: bolder;
}

.contact_flexbox {
    display: flex;
    flex-direction: row;
    gap: 4%;
}

.contact_info_section {
    width: 48%;
}

.contact_form {
    width: 48%;
}

.contact_info {
    padding-top: 25px;
}

.heading_block {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: .8px;
}

.description_block {
    font-size: 16px;
    padding-top: 6px;
    color: #6B7280;
    width: 85%;
}

.field_cont {
    position: relative;
    padding-top: 25px;
}

.field_icon {
    display: block;
    position: absolute;
    color: #334155;
    font-size: 20px;
    top: 11px;
    right: 8px;
    cursor: pointer;
}

.email_cont {
    padding-top: 2px;
}

.inp_label {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
}

.password_cont {
    padding-top: 20px;
}

.password_label_cont {
    display: flex;
    justify-content: space-between;
}

.forgot_pass_lgn {
    color: #16A34A;
    cursor: pointer;
}

.inp_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    background: transparent;
    outline: none;
    font-size: 16px;
    padding-right: 30px;
}

.inp_field:focus {
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.textarea_field {
    width: 100%;
    font: inherit;
    resize: none;
    padding: 12px;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    background: transparent;
    outline: none;
    font-size: 16px;
}

.textarea_field:focus {
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.submit_btn {
    width: 100%;
    background-color: #0F172A;
    text-align: center;
    padding: 14px;
    margin-top: 20px;
    color: #f8fAfc;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color .5s;
    box-shadow: 0 5px 7px 1px #d6d5d5;
}

.submit_btn:hover {
    background-color: #405161;
}

.create_new_acc {
    text-align: center;
    padding-top: 20px;
}

.create_new_acc > .link_color {
    color: #16A34A;
    cursor: pointer;
}


@media screen and (max-width: 1000px) {
    .contact_flexbox {
        flex-direction: column;
    }

    .contact_info_section {
        width: 100%;
    }
    
    .contact_form {
        width: 100%;
        padding-top: 40px;
    }

    .submit_btn {
        width: 200px;
    }
}

@media screen and (max-width: 560px) {
    .contact_heading {
        font-size: 30px;
    }

    .sl_cont {
        padding: 12px 10px;
    }

    .sl_cont_col2 {
        font-size: 14px;
    }

    .inp_label {
        font-size: 14px;
    }

    .forgot_pass_lgn {
        font-size: 14px;
    }
    
    .inp_field {
        font-size: 14px;
    }

    .submit_btn {
        font-size: 14px;
    }

    .create_new_acc {
        font-size: 14px;
    }
}