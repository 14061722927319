.brand_dropdown_main {
    position: absolute;
    float: left;
    top: 52px;
    width: 380px;
    left: 0;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px .3px;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
    background-color: #ffffff;
    z-index: 2;
}

.brand_dropdown_base {
    padding-top: 24px;
    background-color: #ffffff;
}

.brand_dropdown_heading {
    font-size: 16px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    padding-bottom: 10px;
}

.brand_list {
    max-height: 200px;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
}

.brand_list::-webkit-scrollbar {
    width: 5px;
}

.brand_list::-webkit-scrollbar-track {
    background: transparent;
}

.brand_list::-webkit-scrollbar-thumb {
background: #999;
}

.brand_list::-webkit-scrollbar-thumb:hover {
background: #666; 
}

.brand_cont {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #F2F3F5;
}

.obrand_cont {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
}

.brand_footer {
    display: flex;
    justify-content: space-between;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: #F9FAFB;
    padding-left: 24px;
    padding-right: 24px;
}

.clear_btn {
    background: none;
    border-radius: 20px;
    border: 1px solid #d3d5d9;
    padding: 8px 22px;
    width: fit-content;
    cursor: pointer;
    letter-spacing: .5px;
    font-weight: 600;
}

.apply_btn {
    background-color: #0F172A;
    color: #fff;
    border-radius: 20px;
    border: 1px solid #d3d5d9;
    padding: 8px 22px;
    width: fit-content;
    cursor: pointer;
    letter-spacing: .5px;
    font-weight: 600;
}

@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}