.slider_main {
    display: block;
    position: relative;
    background-color: #E3FFE6;
    height: 600px;
}

.slider_base {
    display: flex;
    align-items: center;
    height: 100%;
}

.slider_badge_base {
    position: relative;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    left: 50%;
    right: 50%;
    z-index: 1;
}

.slider_badgecol {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.slider_badge {
    height: 4px;
    width: 80px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    cursor: pointer;
}

.slider_active {
    border-radius: 6px;
    height: 4px;
    background-color: #334155;
    animation: badgeActive .35s;
    -webkit-animation: badgeActive .35s;
    -moz-animation: badgeActive .35s;
    -o-animation: badgeActive .35s;
    -ms-animation: badgeActive .35s;
}

.c_button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    bottom: 50%;
    cursor: pointer;
    padding: 10px;
    height: fit-content;
    z-index: 2;
}

.c_button:hover {
    border: 1px solid #334155;
    border-radius: 50%;
    padding: 9.5px;
}

.c_button.left {
    left: 30px;
}

.c_button.right {
    right: 30px;
}

.slider_icon {
    color: #334155;
    font-size: 24px;
}

.slider_content {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 150px;
    height: 100%;
    width: 100%;
}

.slider_textcol {
    width: 60%;
}

.sm_label {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .6px;
    color: #334155;
    padding-bottom: 20px;
    animation: animateText .8s;
    -webkit-animation: animateText .8s;
    -moz-animation: animateText .8s;
    -o-animation: animateText .8s;
    -ms-animation: animateText .8s;
    z-index: 1;
}

.lg_label {
    position: relative;
    font-size: 65px;
    font-weight: bolder;
    letter-spacing: 2px;
    line-height: 85px;
    color: #0f172A;
    padding-bottom: 20px;
    animation: animateText 1.2s;
    -webkit-animation: animateText 1s;
    -moz-animation: animateText 1.2s;
    -o-animation: animateText 1.2s;
    -ms-animation: animateText 1.2s;
    z-index: 1;
}

.explore_btn {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #0f172A;
    color: #ffffff;
    width: fit-content;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 40px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    animation: animateText 1.6s;
    -webkit-animation: animateText 1.6s;
    -moz-animation: animateText 1.6s;
    -o-animation: animateText 1.6s;
    -ms-animation: animateText 1.6s;
    z-index: 1;
}

.explore_btn:hover {
    background-color: #19243d;
}

.explore_text {
    font-size: 15px;
    letter-spacing: .8px;
    font-weight: 600;
}

.expore_icon {
    font-size: 20px;
}

.slider_imagecol {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    padding-right: 1vw;
    z-index: 0;
}

.slider_image {
    width: 600px;
    animation: animateImage 1s;
    -webkit-animation: animateImage 1s;
    -moz-animation: animateImage 1s;
    -o-animation: animateImage 1s;
    -ms-animation: animateImage 1s;
}

@keyframes animateImage {
    0% {
        margin-top: 50px;
        transform: scale(1.1);
        opacity: 0%;
    }

    100% {
        margin-top: 0px;
        transform: scale(1);
        opacity: 100%;
    }
}

@keyframes animateText {
    0% {
        margin-left: -50px;
        opacity: 0%;
    }

    100% {
        margin-left: 0px;
        opacity: 100%;
    }
}

@keyframes badgeActive {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}


@media screen and (min-width: 1600px) {
    .slider_main {
        display: flex;
        justify-content: center;
    }
    .slider_base {
        width: 1400px;
    }
}

@media screen and (max-width: 1280px) {
    .slider_content {
        padding-left: 150px;
    }

    .slider_textcol {
        width: 70%;
    }

    .lg_label {
        font-size: 50px;
        letter-spacing: .5px;
        line-height: 55px;
    }

    .explore_btn {
        margin-top: 40px;
    }
}

@media screen and (max-width: 1200px) {
    .slider_content {
        padding-left: 60px;
    }
}

@media screen and (max-width: 1000px) {
    .slider_main {
        height: 400px;
    }

    .slider_content {
        padding-left: 60px;
    }

    .slider_textcol {
        width: 70%;
    }

    .slider_image {
        width: 400px;
    }
}

@media screen and (max-width: 815px) {
    .slider_main {
        height: 330px;
    }

    .sm_label {
        font-size: 16px;
        letter-spacing: normal;
    }
    
    .lg_label {
        font-size: 32px;
    }

    .sm_label {
        padding-bottom: 10px;
    }

    .slider_content {
        padding-left: 60px;
        padding-right: 60px;
    }

    .slider_textcol {
        width: 100%;
    }

    .explore_btn {
        margin-top: 0px;
    }
    
    .slider_image {
        width: 330px;
    }
}

@media screen and (max-width: 560px) {
    .slider_content {
        padding-left: 20px;
        padding-right: 20px;
    }

    .lg_label {
        font-size: 28px;
    }

    .sm_label {
        font-size: 14px;
    }

    .explore_btn {
        padding-left: 26px;
        padding-right: 26px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 20px;
        gap: 6px;
    }

    .explore_text {
        font-size: 14px;
        letter-spacing: .2px;
        font-weight: 600;
    }
}

@media screen and (max-width: 350px) {
    .slider_imagecol {
        left: 0;
    }
}