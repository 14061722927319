.cart_dropdown_base {
    position: absolute;
    top: 56px;
    width: 448px;
    right: 0;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;
    z-index: 2;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
}

.cart_dropdown_cont {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
}

.cart_dropdown_heading {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
}

.cart_page_list {
    display: block;
    padding-top: 10px;
}

.cart_list {
    display: block;
    padding-top: 10px;
    max-height: 320px;
    overflow-y: scroll;
}

.cart_list::-webkit-scrollbar {
    width: 0px;
}

/* .cart_list::-webkit-scrollbar-track {
    background: transparent;
} */

.cart_list::-webkit-scrollbar-thumb {
    background: transparent;
}

.cart_list::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.cart_flexbox {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 16px;
}

.cart_flexbox+.cart_flexbox {
    padding-top: 16px;
    border-top: 1px solid #e7e7e7;
}

.cart_page_flexbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 32px;
}

.cart_page_flexbox+.cart_page_flexbox {
    padding-top: 32px;
    border-top: 1px solid #e7e7e7;
}

.prod_icon_base {
    width: 25%;
}

.prod_base {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 75%;
    padding-left: 16px;
}

.prod_desc_base {
    display: block;
    width: 73%;
}

.prod_icon {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
}

.prod_name {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.prod_categories {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6px;
    padding-bottom: 2px;
    opacity: 0.6;
    font-size: 14px;
}

.grouped_categ {
    display: flex;
    align-items: center;
    padding-right: 8px;
}

.grouped_categ+.grouped_categ {
    padding-left: 8px;
    border-left: 1px solid #a3a3a3;
}

.cart_categ_icon {
    font-size: 16px;
    margin-right: 6px;
}

.prod_counter {
    padding-top: 6px;
    padding-bottom: 14px;
}

.prod_amount_base {
    display: flex;
    justify-content: right;
    width: 27%;
}

.prod_amount {
    border-radius: 8px;
    border: 2px solid #22C55E;
    padding: 4px;
    height: fit-content;
    color: #22C55E;
}

.prod_tag_base {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.tag_cont {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border: 1px solid #dddddd;
    border-radius: 16px;
}

.tag_cont>span {
    font-size: 14px;
}

.prod_qty {
    padding-top: 2px;
    padding-bottom: 2px;
    opacity: 0.6;
    font-size: 14px;
}

.prod_remove_base {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.prod_remove {
    color: #0284C7;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.empty_cart_text {
    padding-bottom: 20px;
}

.cart_dropdown_footer {
    background-color: #F8FAFC;
    padding: 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.cart_dd_total {
    display: flex;
    justify-content: space-between;
}

.cart_btns {
    display: flex;
    justify-content: center;
    padding-top: 14px;
}

.footer_text {
    font-weight: bold;
    font-size: 16px;
}

@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 425px) {
    .prod_icon_base {
        width: 100%;
        margin-bottom: 10px;
    }

    .prod_base {
        width: 100%;
        padding-left: 0px;
    }

    .cart_page_flexbox {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
    }

    .cart_page_flexbox+.cart_page_flexbox {
        padding-top: 16px;
    }
}