/* Checkbox */
.checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

.checkbox:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox input:checked~.checkmark {
    background-color: #0EA5E9;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked~.checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    left: 7px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* Radio Button */
.radio {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.radio:hover input~.radiomark {
    background-color: #ccc;
}

.radio input:checked~.radiomark {
    background-color: #0EA5E9;
}

.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio input:checked~.radiomark:after {
    display: block;
}

.radio .radiomark:after {
    top: 6px;
    left: 6px;
    width: 8.5px;
    height: 8.5px;
    border-radius: 50%;
    background: white;
}