/* DOT PULSE */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }

    30% {
        box-shadow: 9984px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }

    30% {
        box-shadow: 9999px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 9999px 0 0 -5px;
    }
}

@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }

    30% {
        box-shadow: 10014px 0 0 2px;
    }

    60%,
    100% {
        box-shadow: 10014px 0 0 -5px;
    }
}

/* INFINITY WORM */
.ip {
    width: 5em;
    height: 2.5em;
}

.ip_sm {
    width: 3.8em;
    height: 2em;
}

.ip__track {
    stroke: hsl(223, 90%, 90%);
    transition: stroke var(0.3s);
}

.ip__worm1,
.ip__worm2 {
    animation: worm1 2s linear infinite;
}

.ip__worm2 {
    animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    .ip__track {
        stroke: hsl(223, 90%, 15%);
    }
}

/* Animation */
@keyframes worm1 {
    from {
        stroke-dashoffset: 0;
    }

    50% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -358;
    }

    50.01% {
        animation-timing-function: linear;
        stroke-dashoffset: 358;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes worm2 {
    from {
        stroke-dashoffset: 358;
    }

    50% {
        stroke-dashoffset: 0;
    }

    to {
        stroke-dashoffset: -358;
    }
}

/* SKELETON */
.skeleton-right {
    flex: 1;
}

.square {
    height: 80px;
    border-radius: 5px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-squares 2s infinite ease-out;
}

.circle {
    border-radius: 50% !important;
    height: 35px !important;
    width: 35px;
}

.line {
    height: 12px;
    margin-bottom: 6px;
    border-radius: 2px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-lines 2s infinite ease-out;
}

.h8 {
    height: 8px !important;
}

.h10 {
    height: 10px !important;
}

.h12 {
    height: 12px !important;
}

.h15 {
    height: 15px !important;
}

.h17 {
    height: 17px !important;
}

.h20 {
    height: 20px !important;
}

.h25 {
    height: 25px !important;
}

.h30 {
    height: 30px !important;
}

.h40 {
    height: 40px !important;
}

.h50 {
    height: 50px !important;
}

.h100 {
    height: 100% !important;
}

.w20 {
    width: 40px !important
}

.w22 {
    width: 50px !important
}

.w25 {
    width: 60px !important
}

.w30 {
    width: 75px !important
}

.w40 {
    width: 100px !important;
}

.w50 {
    width: 50% !important
}

.w75 {
    width: 75% !important
}

.w100 {
    width: 100% !important
}

.m10 {
    margin-bottom: 10px !important;
}

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}


.animate-spin {
    display: flex;
    align-items: center;
    background: transparent;
}

.spin_icon {
    color: #d3d3d3;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(1turn);
    }
}