.fp_base {
    display: flex;
    justify-content: center;
    min-height: 80vh;
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 3vw;
    padding-right: 3vw;
}

.fp_cont {
    width: 450px;
    /* background-color: blueviolet; */
}

.fp_heading {
    font-size: 46px;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bolder;
}

.fp_description {
    font-size: 16px;
    text-align: center;
    padding-bottom: 50px;
}

.fp_form {
    padding-top: 30px;
}

.inputfield_cont {
    position: relative;
}

.inp_label {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
}

.inp_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    background: transparent;
    outline: none;
    font-size: 16px;
    padding-right: 30px;
}

.inp_field:focus {
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.submit_btn {
    width: 100%;
    background-color: #0F172A;
    text-align: center;
    padding: 14px;
    margin-top: 20px;
    color: #f8fAfc;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color .5s;
    box-shadow: 0 5px 7px 1px #d6d5d5;
}

.submit_btn:hover {
    background-color: #405161;
}

.go_back {
    text-align: center;
    padding-top: 20px;
}

.go_back > .link_color {
    color: #16A34A;
    cursor: pointer;
}

@media screen and (max-width: 560px) {
    .fp_heading {
        font-size: 30px;
    }

    .inp_label {
        font-size: 14px;
    }

    .forgot_pass_lgn {
        font-size: 14px;
    }
    
    .inp_field {
        font-size: 14px;
    }

    .submit_btn {
        font-size: 14px;
    }

    .go_back {
        font-size: 14px;
    }
}