.price_dropdown_main {
    position: absolute;
    float: left;
    top: 52px;
    width: 380px;
    left: 0;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px .3px;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
    background-color: #ffffff;
    z-index: 2;
}

.price_dropdown_base {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #ffffff;
}

.price_dropdown_heading {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.price_list {
    display: block;
    padding-top: 14px;
}

.sliders_control {
    position: relative;
    min-height: 40px;
}

.range_cont {
    display: flex;
    padding-bottom: 22px;
}

.values_cont {
    width: 50%;
}

.values_cont + .values_cont {
    margin-left: 6%;
} 

.val_heading {
    font-size: 14px;
    font-weight: 600;
}

.rvalue_cont {
    padding-top: 2px;
}

.rvalue_base {
    display: flex;
    justify-content: space-between;
    padding: 6px 12px;
    border-radius: 20px;
    border: 1px solid #d3d5d9;
}

.price_icon_base {
    display: flex;
    align-items: center;    
}

.price_icon {
    display: block;
    width: 14px;
    height: 14px;
}

.range_footer {
    display: flex;
    justify-content: space-between;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: #F9FAFB;
    padding-left: 24px;
    padding-right: 24px;
}

.clear_btn {
    background: none;
    border-radius: 20px;
    border: 1px solid #d3d5d9;
    padding: 8px 22px;
    width: fit-content;
    cursor: pointer;
    letter-spacing: .5px;
    font-weight: 600;
}

.apply_btn {
    background-color: #0F172A;
    color: #fff;
    border-radius: 20px;
    border: 1px solid #d3d5d9;
    padding: 8px 22px;
    width: fit-content;
    cursor: pointer;
    letter-spacing: .5px;
    font-weight: 600;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #96dbfa;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    -moz-appearance: none;
    pointer-events: all;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #96dbfa;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb:hover {
    background: #f7f7f7;
    box-shadow: 0 0 0 2px #57c5f7;
}

input[type=range]::-webkit-slider-thumb:active {
    box-shadow: inset 0 0 3px #96dbfa, 0 0 9px #96dbfa;
    -webkit-box-shadow: inset 0 0 3px #96dbfa, 0 0 9px #96dbfa;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: #ABE2FB;
    pointer-events: none;
}

#fromSlider {
    height: 0;
    z-index: 1;
}


@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}