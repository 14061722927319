.product_section {
    padding-top: 60px;
}

.product_flex_cont {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
}

.single_prod_heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.product_details_cont {
    padding-top: 30px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 60px;
}

.product_details_heading {
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.product_details_description {
    color: #374151;
    font-size: 16px;
    line-height: 26px;
}

.product_details_description .pd_details {
    display: block;
}

.product_details_description .pd_details + .pd_details {
    margin-top: 20px;
}

.pd_list_body {
    margin-top: 20px;
    margin-left: 32px;
    margin-right: 17px;
    font-size: 16px;
    text-align: justify;
    list-style: disc !important;
}

.pd_desc_skel {
    margin-top: 20px;
}

.pd_list_skel {
    margin-top: 8px;
}

.pd_list + .pd_list {
    margin-top: 8px;
}

.pd_list_heading {
    font-size: 16px;
    font-weight: 600;
}

.pd_list_description {
    font-size: 16px;
    margin-left: 8px;
}

.product_more_suggestions {
    padding-top: 50px;
    padding-bottom: 60px;
}

.square_skel + .square_skel {
    margin-top: 6px;
}

@media screen and (max-width: 1000px) {
    .product_flex_cont {
        flex-direction: column;
    }

}