@import url("https://fonts.googleapis.com/css?family=Poppins");


body {
    color: #111;
    font-size: 15px;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    margin: 0;
    color: #0F172A;
}

* {
    box-sizing: border-box;
}

a {
    color: #059bd6;
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1 {
    font-size: 65px;
    font-weight: bolder;
    letter-spacing: 2px;
    line-height: 85px;
    color: #0f172A;
}

h2 {
    font-size: 50px;
    font-weight: bolder;
    letter-spacing: .5px;
    line-height: 45px;
    color: #0f172A;
}

h3 {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: .2px;
    line-height: 20px;
    color: #0f172A;
}

h4 {
    font-size: 20px;
    font-weight: 600;
    color: #0f172A;
}


@media screen and (max-width: 560px) {
    h2 {
        font-size: 40px;
        font-weight: bolder;
        letter-spacing: .2px;
        line-height: 20px;
    }
}