.search_dropdown_base {
    position: absolute;
    top: 66px;
    width: 600px;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;
    z-index: 2;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
}

.search_dropdown_cont {
    padding-top: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 18px;
    max-height: 400px;
    overflow-y: auto;
}

.search_dropdown_heading {
    display: flex;
    justify-content: right;
    font-size: 12px;
    text-transform: uppercase;
    color: #777;
    padding-bottom: 12px;
}

.search_col_cont {
    display: block;    
}

.search_col_cont + .search_col_cont {
    padding-top: 20px;
}

.search_option {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

.search_option + .search_option {
    border-top: 1px solid #e7e7e7;
    padding-top: 6px;
}

.search_image_cont {
    max-width: 11%;
}

.search_image {
    width: 100%;
    border-radius: 3px;
}

.search_title_cont {
    width: 69%;
}

.search_price_cont {
    width: 16%;
    display: flex;
    justify-content: right;
}

.search_price {
    font-weight: 600;
}

.search_discount_price {
    font-weight: 600;
    color: #c1c1c1;
    text-decoration: line-through;
}

.search_no_data {
    display: flex;
    justify-content: center;
}

@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}