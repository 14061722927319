.search_base {
    padding-bottom: 65px;
}

.search_sec {
    width: 100%;
}

.search_heading {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: .2px;
    color: #0f172A;
    padding-bottom: 6px;
}

.search_desc {
    font-size: 16px;
    color: #4B678F;
    width: 60%;
    padding-bottom: 60px;
}

.filters_sec {
    display: flex;
    justify-content: space-between;
    padding-top: 160px;
    padding-bottom: 15px;
}

.filters_base {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.filter_box {
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #d3d5d9;
    border-radius: 25px;
    cursor: pointer;
}

.active_filter_box {
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #0EA5E9;
    background-color: #f0f9ff;
    border-radius: 25px;
    cursor: pointer;
}

.filter_box + .filter_box {
    margin-left: 6px;
}

.filter_box + .active_filter_box {
    margin-left: 6px;
}

.active_filter_box + .filter_box {
    margin-left: 6px;
}

.active_filter_box + .active_filter_box {
    margin-left: 6px;
}

.filter_icon_base.left {
    padding-left: 10px;
}

.filter_icon_base.right {
    padding-left: 14px;
}

.filter_icon {
    display: block;
    font-size: 18px;
    color: #4B678F;
}

.close_filter_icon {
    display: block;
    font-size: 18px;
    color: #0EA5E9;
}

.filter_text {
    font-size: 14px;
    padding-left: 10px;
    color: #4B678F;
}

.filter_box:hover {
    border-color: #acb4c2;
}

/* Resp Filter */
.r_filters_sec {
    display: none;
    padding-top: 100px;
    padding-bottom: 15px;
}

.products_list {
    display: flex;
    flex-wrap: wrap;
    gap: .9%;
    padding-bottom: 50px;
}

.prodlst_cont {
    width: 24.3%;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}

.prodlst_cont2 {
    width: 24.3%;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* .prodlst_cont + .prodlst_cont {
    margin-left: .8%;
} */

.prodlst_cont2 + .prodlst_cont {
    margin-left: .8%;
}

.rt_page_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 14px;
    color: #333;
}

.no_data {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1300px) {
    .prodlst_cont {
        width: 32.5%;
    }
}

@media screen and (max-width: 1200px) {
    .prodlst_cont {
        width: 24.3%;
    }
}

@media screen and (max-width: 1100px) {
    .prodlst_cont {
        width: 32.5%;
    }
}

@media screen and (max-width: 1000px) {
    .filters_sec {
        display: none;
    }
    .r_filters_sec {
        display: block;
    }
}

@media screen and (max-width: 950px) {
    .prodlst_cont {
        width: 48.2%;
    }
    .search_desc {
        width: 75%;
    }
}

@media screen and (max-width: 650px) {
    .prodlst_cont {
        width: 100%;
    }
    .search_desc {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {}

@media screen and (max-width: 560px) {}

@media screen and (max-width: 500px) {}