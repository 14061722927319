.about_section_one {
    display: flex;
    align-items: center;
    gap: 3%;
    padding-top: 65px;
    padding-bottom: 65px;
}

.about_intro {
    width: 42%;
}

.about_desc {
    font-size: 18px;
    color: #4B678F;
}

.about_image_cont {
    width: 55%;
}

.about_image {
    width: 100%;
}

.about_section_two {
    padding-top: 65px;
    padding-bottom: 65px;
}

.about_head_desc {
    display: block;
}

.about_founder_cont {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    padding-top: 50px;
}

.about_founder_card {
    width: 23%;
}

.founder_image_cont {
    width: 100%;
    height: 300px;
}

.founder_card_image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.about_founder_name {
    font-size: 20px;
    font-weight: 600;
    color: #0f172A;
    padding-top: 18px;
}

.about_founder_desc {
    padding-top: 3px;
    color: #837280;
    font-size: 16px;
}

.about_audience_cont {
    display: flex;
    flex-direction: row;
    gap: 2%;
    flex-wrap: wrap;
    padding-top: 50px;
}

.about_audience_card {
    padding: 20px;
    border-radius: 18px;
    background-color: #F9FAFB;
    width: 32%;
}

.about_audience_title {
    font-size: 30px;
    font-weight: 600;
    color: #0f172A;
}

.about_audience_desc {
    padding-top: 4px;
    color: #837280;
    font-size: 16px;
}

@media screen and (max-width: 1000px) {
    .about_section_one {
        flex-direction: column;
        align-items: baseline;
    }

    .about_intro {
        width: 100%;
    }
    
    .about_image_cont {
        width: 100%;
        padding-top: 60px;
    }

    .about_founder_card {
        width: 48%;
    }

    .about_founder_card {
        padding-top: 40px;
    }

    .about_founder_cont {
        padding-top: 0px;
    }

    .about_audience_cont {
        flex-direction: column;
        padding-top: 20px;
    }

    .about_audience_card {
        margin-top: 20px;
        width: 100%;
    }
}

@media screen and (max-width: 560px) {
    .about_desc {
        font-size: 15px;
    }

    .about_founder_name {
        font-size: 18px;
    }
    
    .about_founder_desc {
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    
    .about_founder_card {
        width: 100%;
    }

    .about_founder_card {
        padding-top: 40px;
    }

    .about_founder_cont {
        padding-top: 0px;
    }
}