.footer_main {
    display: block;
}

.newsletter_main {
    background-color: #0f172A;
    width: 100%;
    padding: 40px 150px;
}

.newsletter_base {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.nl_text_base {
    width: 50%;
}

.nl_text {
    display: block;
}

.nl_sm {
    text-transform: uppercase;
    color: #b1b1b1;
    font-size: 16px;
}

.nl_bg {
    text-transform: uppercase;
    color: #f9f9f9;
    font-size: 26px;
}

.nl_email_base {
    width: 30%;
}

.nl_input_cont {
    position: relative;
    display: flex;
    justify-content: right;
}

.nl_inputbar {
    width: 400px;
    border: none;
    outline: none;
    font-size: medium;
    font: inherit;
    padding: 8px;
    margin-left: 6px; 
    padding-right: 80px;
    box-sizing: inherit;
    background-color: white;
    border-radius: 8px;
}

.nl_input_btn {
    position: absolute;
    top: 8px;
    right: 4px;
    cursor: pointer;
}

.nl_submit {
    color: #fff;
    background-color: #0f172A;
    padding: 4px 8px;
    border-radius: 8px;
}

.footer_main_cont {
    width: 100%;
    padding: 80px 150px;
    background-color: #F6F6F8;
}

.footer_base {
    display: flex;
    flex-wrap: wrap;
}

.cs_footer_col {
    width: 25%;
}

.b_footer_col {
    width: 22%;
    padding-left: 6px;
    padding-right: 6px;
}

.fi_footer_col {
    width: 25%;
    padding-left: 6px;
    padding-right: 6px;
}

.git_footer_col {
    width: 28%;
}

.footer_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.fc_icon_cont {
    display: none;
    width: 20%;
    justify-content: right;
    align-items: center;
}

.f_comp_icon {
    font-size: 22px;
}

.footer_col_heading {
    font-weight: 600;
    width: 80%;
}

.footer_logo_cont {
    display: flex;
    justify-content: left;
    margin-bottom: 26px;
}

.f_logo {
    width: 170px;
    cursor: pointer;
}

.footer_social_icons {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.footer_icon_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #a1a1a1;
    border-radius: 50%;
    cursor: pointer;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-right: 6px;
    color: #0f172A;
    border-color: #0f172A;
}

.footer_icon_cont:hover {
    color: #fff;
    background-color: #0f172A;
}

.footer_facebook_icon {
    font-size: 20px;   
}

.footer_instagram_icon {
    font-size: 24px;
}

.footer_tiktok_icon {
    font-size: 18px;
}

.f_col_content {
    display: block;
    margin-top: 16px;
}

.f_col_content2 {
    display: none;
    margin-top: 16px;
}

.f_brand_cont {
    display: block;
    color: initial !important;
    cursor: pointer;
}

.f_brand_cont:hover {
    color: #334155;
}

.f_brand_cont + .f_brand_cont {
    margin-top: 10px;
}

.f_brand_cont2 {
    display: block;
    cursor: pointer;
    padding-left: 18px;
}

.f_brand_cont2::before {
    content: "-\00a0";
}

.f_brand_cont2:hover {
    color: #334155;
}

.f_brand_cont2 + .f_brand_cont2 {
    margin-top: 16px;
}

.f_inf_cont {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.f_inf_icon {
    display: block;
    font-size: 22px;
}

.f_inf_cont + .f_inf_cont {
    margin-top: 10px;
}

@media screen and (min-width: 1600px) {
    .newsletter_main {
        display: flex;
        justify-content: center;
    }

    .footer_main_cont {
        display: flex;
        justify-content: center;
    }

    .newsletter_base {
        width: 1115px;
    }

    .footer_base {
        width: 1115px;
    }
}

@media screen and (max-width:1200px) {
    .newsletter_main {
        padding: 30px 60px;
    }

    .footer_main_cont {
        padding: 80px 60px;
    }
}

@media screen and (max-width:1000px) {
    .cs_footer_col {
        width: 50%;
        padding-bottom: 24px;
        border-bottom: 1px solid #e7e7e7;
    }
    
    .b_footer_col {
        width: 50%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 24px;
        border-bottom: 1px solid #e7e7e7;
    }
    
    .fi_footer_col {
        width: 50%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 24px;
    }

    .git_footer_col {
        width: 50%;
        padding-top: 24px;
    }
}

@media screen and (max-width:750px) {
    .nl_inputbar {
        margin-left: 0px;
    }
    
    .nl_input_btn {
        right: 4px;
    }
    
    .nl_text_base {
        width: 40%;
    } 
    
    .nl_email_base {
        width: 58%;
    } 
}

@media screen and (max-width:560px) {
    .newsletter_main {
        padding: 12px 20px;
    }

    .nl_sm {
        font-size: 14px;
    }
    
    .nl_bg {
        font-size: 20px;
    }

    .nl_inputbar {
        font-size: 12px;
    }

    .nl_submit {
        font-size: 12px;
    } 

    .nl_input_btn {
        top: 5px;
    }

    .footer_main_cont {
        padding: 80px 20px;
    }

    .footer_col_heading {
        font-size: 14px;
    }

    .cs_footer_col {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
    }
    
    .b_footer_col {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        padding-top: 24px;
    }
    
    .fi_footer_col {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .git_footer_col {
        width: 100%;
    }
    
    .fc_icon_cont {
        display: flex;
    }

    .f_col_content {
        display: none;
        margin-top: 16px;
    }

    .f_col_content2 {
        display: block;
        margin-top: 16px;
    }
}

@media screen and (max-width:450px) {
    .nl_text_base {
        width: 100%;
    } 
    
    .nl_email_base {
        margin-top: 12px;
        width: 100%;
    }
}