.main_container {
    min-height: 1000px; 
    padding-top: 68px;   
}

.sections_adj {
    display: block;
}

.sections_base {
    padding-left: 150px;
    padding-right: 150px;
    width: 100%;
}

.sections_base2 {
    display: block;
}

.sections_main {
    display: block;
}

.sections_main2 {
    display: block;
    width: 100%;
}

.sections_main3 {
    display: block;
    position: relative;
}

.section_heading_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 150px;
    padding-right: 150px;
}

.section_heading_cont2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sec_heading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: .8px;
}

.sec_heading_sm {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
}

.sec_heading span {
    color: #6B7280;
}

.sec_heading_sm span {
    color: #6B7280;
}

.sec_heading_flex {
    width: 50%;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: .8px;
}

/* Product Card */
.product_card {
    display: block;
    position: relative;
}

.product_type {
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
    gap: 4px;
    padding: 4px 8px;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    z-index: 1;
}

.product_type_nobg {
    display: flex;
    gap: 4px;
    align-items: center;
    z-index: 1;
}


.product_type_icon {
    display: block;
    font-size: 14px;
}

.product_type_icon_nobg {
    display: block;
    font-size: 15px;
}

.product_type_text {
    display: block;
    font-size: 12px;
}

.product_type_text_nobg {
    display: block;
    font-size: 13px;
}

.product_wishlist {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 6px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
}

.wishlist_icon_outline {
    display: block;
    font-size: 24px;
}

.wishlist_icon_filled {
    display: block;
    color: #EF4444;
    font-size: 24px;
}

.product_img_cont {
    position: relative;
    border-radius: 20px;
    z-index: 0;
}

.product_img_load {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
}

.small_imgs_loader {
    padding: 14px;
}

.flexbox_product_btns {
    display: flex;
    justify-content: center;
    gap: 6px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    z-index: 1;
    opacity: 0;
    animation: hideProductBtns .2s;
    -webkit-animation: hideProductBtns .2s;
    -moz-animation: hideProductBtns .2s;
    -o-animation: hideProductBtns .2s;
    -ms-animation: hideProductBtns .2s;
}

.flexbox_product_btns.show {
    opacity: 1;
    bottom: 26px;
    animation: showProductBtns .2s;
    -webkit-animation: showProductBtns .2s;
    -moz-animation: showProductBtns .2s;
    -o-animation: showProductBtns .2s;
    -ms-animation: showProductBtns .2s;
}

.btn_dark {
    display: flex;
    align-items: center;
    background-color: #0f172A;
    color: #ffffff;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 20px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 2px 2px 1px #d6d5d5;
    transition: background-color .15s;
}

.btn_dark:hover {
    background-color: #19243d;
}

.dark_icon {
    display: block;
    font-size: 14px;
}

.dark_text {
    font-size: 12px;
}

.btn_light {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    color: #0f172A;
    width: fit-content;
    padding: 6px 12px;
    border-radius: 20px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 2px 2px 1px #d6d5d5;
    transition: background-color .15s;
}

.btn_light:hover {
    background-color: #f1f1f1;
}

.light_icon {
    display: block;
    font-size: 14px;
}

.light_text {
    font-size: 12px;
}

.product_img {
    width: 100%;
    border-radius: 20px;
}

.scrollable_product_img {
    width: 300px;
    border-radius: 20px;
}

.product_desc_cont {
    padding: 6px;
}

.product_designs_cont {
    display: flex;
    align-items: center;
    gap: 10px;
}

.product_design_base {
    border: 2px solid #999;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: all .15s;
}

.product_design_base:hover,
.product_design_base.active {
    border: 2.5px solid #222;
}

.product_design_img {
    width: 100%;
    border-radius: 50%;
}

.product_more_designs_length {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #999;
    color: #888;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 14px;
}

.product_title {
    display: block;
    padding-top: 14px;
}

.product_heading {
    font-size: 16px;
    letter-spacing: .5px;
    font-weight: bolder;
    color: initial !important;
    transition: all .2s;
}

.product_heading:hover {
    color: #43464e !important;
}

.product_desc {
    padding-top: 4px;
    color: #777e87;
    font-size: 14px;
}

.product_price_rating_cont {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    align-items: flex-end;
}

.product_price_base {
    display: flex;
    justify-content: right;
    align-items: center;
}

.product_price {
    border-radius: 8px;
    border: 2px solid #22C55E;
    padding: 4px;
    height: fit-content;
    color: #22C55E;
}

.product_rating {
    display: flex;
    color: #777e87;
    font-size: 14px;
    align-items: center;
    gap: 4px;
}

.product_rating span {
    display: block;
}

.rating_star {
    display: block;
    color: #FBBF24;
    font-size: 20px;
}

.notification_container {
    position: fixed;
    top: 16px;
    right: 30px;
    z-index: 4;
    background: transparent;
}

@keyframes showProductBtns {
    0% {
        opacity: 0;
        bottom: 10px;
    }
    100% {
        opacity: 1;
        bottom: 26px;
    }
}

@keyframes hideProductBtns {
    0% {
        opacity: 1;
        bottom: 26px;
    }
    100% {
        opacity: 0;
        bottom: 10px;
    }
}

@media screen and (min-width: 1600px) {
    .sections_adj {
        display: flex;
        justify-content: center;
    }    
    .sections_base {
        width: 1400px;
    }

    .sections_main2 {
        width: 1400px;
    }
}

@media screen and (max-width: 1325px) {
    .light_text {
        font-size: 10px;
    }
    .light_icon {
        font-size: 12px;
    }
    .dark_text {
        font-size: 10px;
    }
    .dark_icon {
        font-size: 12px;
    }
}

@media screen and (max-width: 1300px) {
    .light_text {
        font-size: 12px;
    }
    .light_icon {
        font-size: 14px;
    }
    .dark_text {
        font-size: 12px;
    }
    .dark_icon {
        font-size: 14px;
    }
}

@media screen and (max-width: 1200px) {
    .sections_base {
        padding-left: 60px;
        padding-right: 60px;
    }

    .section_heading_cont {
        padding-left: 60px;
        padding-right: 60px;
    }

    .sec_heading {
        font-size: 32px;
    }
    
    .sec_heading_flex {
        font-size: 32px;
    }
    .light_text {
        font-size: 10px;
    }
    .light_icon {
        font-size: 12px;
    }
    .dark_text {
        font-size: 10px;
    }
    .dark_icon {
        font-size: 12px;
    }
}

@media screen and (max-width: 1100px) {
    .light_text {
        font-size: 12px;
    }
    .light_icon {
        font-size: 14px;
    }
    .dark_text {
        font-size: 12px;
    }
    .dark_icon {
        font-size: 14px;
    }
}

@media screen and (max-width: 950px) {
    .sec_heading {
        font-size: 28px;
    }
    
    .sec_heading_flex {
        font-size: 28px;
    }
}

@media screen and (max-width: 815px) {
    .sec_heading {
        font-size: 26px;
    }

    .sec_heading_flex {
        font-size: 26px;
    }
}

@media screen and (max-width: 560px) {
    .sections_base {
        padding-left: 20px;
        padding-right: 20px;
    }

    .section_heading_cont {
        padding-left: 20px;
        padding-right: 20px;
    }

    .models_base {
        padding-left: 2vw;
        padding-right: 2vw;
    }

    .sec_heading {
        font-size: 20px;
    }

    .sec_heading_flex {
        font-size: 20px;
    }
}