.custom-select {
    transition: all 1s;
    position: relative;
}

.select-header {
    cursor: pointer;
    padding: 12px;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    background: transparent;
    outline: none;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-header.open {
    border-bottom: 1px solid transparent;
    border-radius: 12px 12px 0px 0px;
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.arrow_base {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.arrow_icon {
    font-size: 22px;
    color: #19243D;
}

.options-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
    max-height: 300px;
    overflow-y: scroll;
}

.options-list li {
    padding: 10px;
    cursor: pointer;
}

.options-list li:hover {
    background-color: #f5f5f5;
}

.select_base {
    padding: 12px;
    border: 1px solid #e7e7e7;
    background: transparent;
    outline: none;
    font-size: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.select_base:focus {
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.select_options {
    width: 100%;
    transition: all 1s;
    padding: 12px;
    outline: none;
    border: none;
    border-bottom: 1px solid #999;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}