.sec_arrows_cont {
    display: flex;
    gap: 20px;
}

.sec_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #79879A;
    border-radius: 50%;
    cursor: pointer;
    color: #79879A;
} 

.sec_arrow_icon {
    font-size: 20px;
}

.sec_arrow:hover {
    border-color: #626d7e;
    color: #79879A;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.arrival_base {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-top: 40px;
    gap: 20px;
    scroll-behavior: smooth;
}

.arrival_base::-webkit-scrollbar {
    width: 0px;
}

.arrival_base::-webkit-scrollbar-track {
    background: transparent;
}

.arrival_base::-webkit-scrollbar-thumb {
background: transparent;
}

.arrival_base::-webkit-scrollbar-thumb:hover {
background: transparent; 
}