.user_dropdown_base {
    position: absolute;
    top: 56px;
    width: 260px;
    right: 0;
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
    background-color: #ffffff;
    z-index: 2;
}

.user_flexbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8%;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 16px;
}

.user_icon_base {
    width: 22%;
}

.user_name_base {
    width: 70%;
}

.user_icon {
    width: 100%;
    border-radius: 50%;
}

.user_name {
    font-weight: bold;
    font-size: 16px;
}

.user_opt_flexbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.user_opt_flexbox:hover {
    background-color: #F1F5F9;
}

.opt_icon_base {
    width: 18%;
}

.opt_name_base {
    width: 82%;
}

.opt_icon {
    display: block;
    color: #334155;
    font-size: 22px;
}

.opt_name {
    color: #334155;
    font-size: 14px;
}

@media screen and (max-width:560px) {
    .user_dropdown_base {
        width: 228px;
        right: -20px;
    }

    .user_name {
        font-size: 14px;
    }

}

@-webkit-keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
@keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
}