.p_modal_counter_cont {
    display: flex;
    align-items: center;
}

.p_modal_counter_btn {
    border: 1.5px solid #9FA6B1;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
}

.p_modal_counter_btn_disable {
    border: 1.5px solid #9FA6B1;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 50%;
    opacity: 0.4;
}

.p_modal_counter_btn:hover {
    border-color: #333;
}

.counter_btn_icon {
    display: block;
    font-size: 16px;
    color: #6A6F78;
}

.p_modal_counter_text {
    color: #6D727D;
    text-align: center;
    min-width: 70px;
}


@media screen and (max-width: 530px) {
    .p_modal_counter_btn {
        border: 1px solid #9FA6B1;
        padding: 4px;
    }
    
    .p_modal_counter_btn_disable {
        border: 1px solid #9FA6B1;
        padding: 4px;
    }

    .counter_btn_icon {
        font-size: 14px;
    }
    
    .p_modal_counter_text {
        min-width: 40px;
        font-size: 12px;
    }
}