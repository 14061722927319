.product_section {
    padding-top: 60px;
}

.product_flex_cont {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
}

.single_prod_heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.product_details_cont {
    padding-top: 30px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 60px;
}

.product_details_heading {
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.product_details_description {
    color: #374151;
    font-size: 16px;
    line-height: 26px;
}

.product_more_suggestions {
    padding-top: 50px;
    padding-bottom: 60px;
}

.select_cont {
    position: relative;
    padding-top: 24px;
    padding-bottom: 14px;
}

.select_label {
    padding-bottom: 6px;
    font-weight: 600;
    color: #333;
}

.select_icon {
    position: absolute;
    right: 9px;
    bottom: 25px;
    font-size: 22px;
    color: #19243D;
}

@media screen and (max-width: 1000px) {
    .product_flex_cont {
        flex-direction: column;
    }

}