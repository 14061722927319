.models_flexbox {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    gap: 1%;
    width: 100%;
}

.model_box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0px;
    margin-top: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 20px;
    width: 19%;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all .25s;
    cursor: pointer;
}

.model_skeleton {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0px;
    margin-top: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 20px;
    width: 19%;
    height: 150px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: all .25s;
    cursor: pointer;
    background-color: #EBEBEB;
}

.model_box:hover {
    top: -3px
}

@media screen and (max-width: 1160px) {
    .model_box {
        width: 24%;
    }

    .model_skeleton {
        width: 24%;
    }
}

@media screen and (max-width: 950px) {
    .model_box {
        width: 49%;
        height: 180px;
    }

    .model_skeleton {
        width: 49%;
        height: 180px;
    }
}

@media screen and (max-width: 815px) {
    .model_box {
        height: 120px;
    }
    .model_skeleton {
        height: 120px;
    }
}

@media screen and (max-width: 560px) {
    .model_box {
        height: 100px;
    }
    .model_skeleton {
        height: 100px;
    }
}

@media screen and (max-width: 400px) {
    .models_flexbox {
        gap: 0px;
        padding-top: 10px;
    }
    
    .model_box {
        min-width: 100%;
        height: 120px;
    }
    .model_skeleton {
        min-width: 100%;
        height: 120px;
    }
}