.f_modal_cont {
    position: relative;
    width: 900px;
    height: 100%;
    border-radius: 3px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
}

.f_modal_header {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dedede;
    font-size: 18px;
    font-weight: 600;
}

.f_modal_close {
    position: absolute;
    top: 20px;
    left: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
    transition: all ease .1s;
}

.f_modal_close:hover {
    background-color: #f5f5f5;
}

.f_modal_close_icon {
    font-size: 22px;
    color: #333;
}

.f_modal_body {
    height: 100%;
    padding-bottom: 150px;
}

.f_modal_scrollable::-webkit-scrollbar {
    width: 6px;
}

.f_modal_scrollable::-webkit-scrollbar-track {
    background-color: #dedede;
}

.f_modal_scrollable::-webkit-scrollbar-thumb {
    background-color: #888;
}

.f_modal_scrollable {
    overflow-y: auto;
    max-height: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.f_section_block {
    padding-top: 32px;
    padding-bottom: 30px;
}

.f_section_block+.f_section_block {
    border-top: 1px solid #dedede;
}

.f_heading {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
}

.f_flex_cont {
    display: flex;
    flex-wrap: wrap;
}

.f_halfw_col {
    width: 50%;
    padding-top: 6px;
    padding-bottom: 6px;
}

.f_footer_section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding-right: 40px;
    padding-left: 40px;
    justify-content: space-between;
    background-color: #F9FAFB;
    padding-top: 20px;
    padding-bottom: 20px;
}

.f_halfw_col_ralign {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 50%;
    padding-top: 6px;
    padding-bottom: 6px;
}

.f_modal_sale_heading {
    font-weight: bold;
}

.f_modal_sale_desc {
    font-size: 12px;
    color: #777;
}

@media screen and (max-width: 560px) {
    .f_modal_scrollable {
        padding-left: 20px;
        padding-right: 20px;
    }

    .f_footer_section {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media screen and (max-width: 510px) {
    .f_halfw_col {
        width: 100%;
    }
}