.file-upload {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}

.file-upload.drag-over {
    background-color: #f0f0f0;
}

.file-input {
    display: none;
}

.file_info {
    display: flex;
    align-items: center;
    background-color: #ccc;
    padding: 10px;
}

.file_img_cont {
    display: flex;
    align-items: center;
    width: 20%;
}

.file_img {
    width: 80%;
}

.file_name {
    width: 70%;
    padding-left: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file_remove {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 10%;
}

.file_remove_icon {
    color: #0f172a;
    cursor: pointer;
    width: 24px;
    height: 24px;
}