.addcart_card {
    width: 450px;
    padding: 14px 16px;
    border-radius: 8px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;
    animation: fadeRtoL .5s;
    -webkit-animation: fadeRtoL .5s;
    -moz-animation: fadeRtoL .5s;
}

.addcart_card.move {
    opacity: 0;
    animation: fadeLtoR .5s;
    -webkit-animation: fadeLtoR .5s;
    -moz-animation: fadeLtoR .5s;
}

.addcart_card.hide {
    display: none;
}

.addcart_card + .addcart_card {
    margin-top: 12px;
} 

.ac_heading {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
    border-bottom: 1px solid #dedede;
}

.ac_flexbox {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
}

.ac_flexbox+.ac_flexbox {
    border-top: 1px solid #e7e7e7;
}

.card_icon_base {
    width: 25%;
}

.ac_prod_base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 75%;
    padding-left: 16px;
}

.ac_qty_main {
    display: flex;
    justify-content: space-between;
}

.ac_title_main {
    display: flex;
    justify-content: space-between;
}

.ac_desc_base {
    display: block;
}

.ac_amount_base {
    display: flex;
    justify-content: right;
    min-width: 70px;
}

.ac_amount {
    border-radius: 8px;
    border: 2px solid #22C55E;
    padding: 4px;
    height: fit-content;
    color: #22C55E;
}

@keyframes fadeRtoL {
    0% {
        margin-right: -50px;
        opacity: 0;
    }
    100% {
        margin-right: 0px;
        opacity: 1;
    }
}

@keyframes fadeLtoR {
    0% {
        margin-right: 0px;
        opacity: 1;
    }
    100% {
        margin-right: -50px;
        opacity: 0;
    }
}