.sec_arrows_cont {
    display: flex;
    gap: 20px;
}

.sec_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid #79879A;
    border-radius: 50%;
    cursor: pointer;
    color: #79879A;
} 

.sec_arrow_icon {
    font-size: 20px;
}

.sec_arrow:hover {
    border-color: #626d7e;
    color: #79879A;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.collection_base {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-top: 40px;
    padding-left: 150px;
    padding-right: 30vw;
    gap: 20px;
    scroll-behavior: smooth;
}

.collection_base::-webkit-scrollbar {
    width: 0px;
}

/* .collection_base::-webkit-scrollbar-track {} */

.collection_base::-webkit-scrollbar-thumb {
background: transparent;
}

.collection_base::-webkit-scrollbar-thumb:hover {
background: transparent; 
}

.collection_card {
    position: relative;
    display: flex;
    height: 280px;
    gap: 8px;
    min-width: 500px;
    border-radius: 20px;
    padding: 30px;
    cursor: pointer;
    transition: all .25s;
}

.collection_card.pink {
    background-color: #FEFCE8;
}

.collection_card.pink:hover {
    background-color: #F4F2DF;
}

.collection_card.blue {
    background-color: #EFF6FF;
}

.collection_card.blue:hover {
    background-color: #E6ECF5;
}

.collection_col {
    width: 50%;
}

.collcard_subheading {
    font-size: 14px;
    padding-bottom: 6px;
}

.collcard_heading {
    font-size: 24px;
    font-weight: bold;
    padding-top: 6px;
}

.collcard_showbtn {
    position: absolute;
    bottom: 30px;
    left: 30px;
    border-radius: 20px;
    padding: 12px 22px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 14px;
    transition: all .15s;
}

.collcard_showbtn:hover {
    background-color: #F3F4F6;
}

.collection_img {
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1200px) {
    .collection_base {
        padding-left: 60px;
    }
}

@media screen and (max-width: 560px) {
    .collection_base {
        padding-left: 20px;
    }
}