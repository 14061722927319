/* CART HEADER DROPDOWN */
.cart_dd_btn {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #0f172A;
    justify-content: center;
    color: #ffffff;
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 40px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    z-index: 1;
}

.cart_dd_btn_light {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: center;
    color: #0f172A;
    width: 100%;
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 40px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    z-index: 1;
}

.cart_dd_btn_light:hover {
    background-color: #f8f8f8;
}

.cart_dd_btn_light + .cart_dd_btn {
    margin-left: 6px;
}

.cart_dd_btn:hover {
    background-color: #19243d;
}

.cart_dd_text {
    font-size: 15px;
    letter-spacing: .8px;
    font-weight: 600;
}

.cart_btn_icon {
    display: block;
    font-size: 22px;
}


/* Filter Modal Buttons */
.f_modal_btn_dark {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #0f172A;
    justify-content: center;
    color: #ffffff;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    z-index: 1;
}

.f_modal_btn_light {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    justify-content: center;
    color: #0f172A;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    gap: 6px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    z-index: 1;
}

.f_modal_btn_light:hover {
    background-color: #f8f8f8;
}

.f_modal_btn_dark:hover {
    background-color: #19243d;
}

.f_modal_btn_text {
    font-size: 15px;
    letter-spacing: .2px;
}


.showmore_btn {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #0f172A;
    color: #ffffff;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 30px;
    gap: 10px;
    cursor: pointer;
    box-shadow: 0 5px 7px 1px #d6d5d5;
    transition: background-color .15s;
    animation: animateText 1.6s;
    -webkit-animation: animateText 1.6s;
    -moz-animation: animateText 1.6s;
    -o-animation: animateText 1.6s;
    -ms-animation: animateText 1.6s;
    z-index: 1;
}

.showmore_btn:hover {
    background-color: #19243d;
}

.showmore_text {
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 600;
}

.showmore_icon {
    font-size: 20px;
}