.signup_base {
    display: flex;
    justify-content: center;
    min-height: 80vh;
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 3vw;
    padding-right: 3vw;
}

.signup_cont {
    width: 450px;
    /* background-color: blueviolet; */
}

.signup_heading {
    font-size: 46px;
    text-align: center;
    padding-bottom: 80px;
    font-weight: bolder;
}

.sl_cont {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #F0F9FF;
    border-radius: 8px;
    padding: 12px 26px;
    cursor: pointer;
    top: 0px;
    transition: all .16s;
}

.sl_cont + .sl_cont {
    margin-top: 12px;
}

.sl_cont:hover {
    top: -2px
} 

.sl_cont_col1 {
    display: flex;
    align-items: center;
    width: 20px;
}

.sl_cont_col2 {
    text-align: center;
    width: 100%;
    font-weight: 500;
}

.linebreak_or {
    position: relative;
    padding-top: 40px;
    border-bottom: 1px solid #e7e7e7;
}

.lb_or_cont {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 30px;
}

.lb_or_text {
    width: 50px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.signup_form {
    padding-top: 30px;
}

.inputfield_cont {
    position: relative;
}

.field_icon {
    display: block;
    position: absolute;
    color: #334155;
    font-size: 20px;
    top: 11px;
    right: 8px;
    cursor: pointer;
}

.email_cont {
    padding-top: 2px;
}

.inp_label {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
}

.password_cont {
    padding-top: 20px;
}

.password_label_cont {
    display: flex;
    justify-content: space-between;
}

.forgot_pass_lgn {
    color: #16A34A;
    cursor: pointer;
}

.inp_field {
    width: 100%;
    padding: 12px;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    background: transparent;
    outline: none;
    font-size: 16px;
    padding-right: 30px;
}

.inp_field:focus {
    border-color: #91deff;
    box-shadow: 0 0 6px 1.2px #b0e2fa;
}

.submit_btn {
    width: 100%;
    background-color: #0F172A;
    text-align: center;
    padding: 14px;
    margin-top: 20px;
    color: #f8fAfc;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color .5s;
    box-shadow: 0 5px 7px 1px #d6d5d5;
}

.submit_btn:hover {
    background-color: #405161;
}

.create_new_acc {
    text-align: center;
    padding-top: 20px;
}

.create_new_acc > .link_color {
    color: #16A34A;
    cursor: pointer;
}

@media screen and (max-width: 560px) {
    .signup_heading {
        font-size: 30px;
    }

    .sl_cont {
        padding: 12px 10px;
    }

    .sl_cont_col2 {
        font-size: 14px;
    }

    .inp_label {
        font-size: 14px;
    }

    .forgot_pass_lgn {
        font-size: 14px;
    }
    
    .inp_field {
        font-size: 14px;
    }

    .submit_btn {
        font-size: 14px;
    }

    .create_new_acc {
        font-size: 14px;
    }
}