.toggle_cont_main {
    display: block;
}

.toggle_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F8FB;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.toggle_skel + .toggle_skel {
    margin-top: 8px;
}

.toggle_header:hover {
    background-color: #EEF1F6;
}

.toggle_heading {
    font-weight: 600;
    color: #333;
}

.toggle_icon {
    font-size: 16px;
    color: #333;
}

.toggle_body {
    margin-top: 20px;
    margin-left: 17px;
    margin-right: 17px;
    font-size: 14px;
    text-align: justify;
    color: #515E71;
}

.toggle_list_body {
    margin-top: 20px;
    margin-left: 32px;
    margin-right: 17px;
    font-size: 14px;
    text-align: justify;
    color: #515E71;
    list-style: disc !important;
}

.toggle_list {
    font-size: 14px;
}

.toggle_list+.toggle_list {
    margin-top: 4px;
}


/* Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #0F766E;
}

input:focus+.slider {
    box-shadow: 0 0 1px #0F766E;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}