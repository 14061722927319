.cart_base {
    padding-top: 65px;
    padding-bottom: 65px;
}

.cart_sec {
    border-bottom: 1px solid #e1e7ee;
}

.cart_heading {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: .2px;
    color: #0f172A;
    padding-bottom: 6px;
}

.cart_desc {
    font-size: 16px;
    color: #4B678F;
    width: 60%;
    padding-bottom: 60px;
}

.cart_products_sec {
    display: flex;
    padding-top: 60px;
    padding-bottom: 15px;
}

.cart_products_cont {
    width: 60%;
    padding-right: 80px;
}

.cart_total_cont {
    width: 40%;
    padding-left: 80px;
}

.cart_total {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
}

.cart_products_cont+.cart_total_cont {
    border-left: 1px solid #dedede;
}

.cart_total_heading {
    font-size: 18px;
    letter-spacing: .5px;
    font-weight: 600;
    margin-bottom: 16px;
}

.cart_title_cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.cart_title_cost+.cart_title_cost {
    border-top: 1px solid #dedede;
}

.cart_cost_title {
    font-size: 14px;
    color: #999;
}

.cart_order_title {
    font-weight: 600;
}

.cart_cost_amount {
    font-size: 14px;
    font-weight: 600;
}

.cart_total_btn {
    margin-top: 16px;
}


@media screen and (max-width: 1000px) {
    .cart_products_sec {
        display: block;
    }

    .cart_products_cont {
        width: 100%;
        padding-right: 0px;
        padding-bottom: 10px;
    }

    .cart_total_cont {
        width: 100%;
        padding-left: 0px;
        padding-top: 32px;
        border-top: 1px solid #dedede;
    }

    .cart_products_cont+.cart_total_cont {
        border-left: 0px;
    }
}

@media screen and (max-width: 950px) {
    .cart_desc {
        width: 75%;
    }
}

@media screen and (max-width: 650px) {
    .cart_desc {
        width: 100%;
    }
}

@media screen and (max-width: 530px) {
    .prod_name {
        font-size: 14px;
    }

    .prod_amount {
        font-size: 12px;
    }

    .cart_categ_icon {
        font-size: 14px;
        margin-right: 3px;
    }

    .tag_cont {
        padding: 1.5px 6px;
        border-radius: 14px;
    }

    .tag_cont>span {
        font-size: 12px;
    }

    .prod_remove {
        font-size: 12px;
    }
}