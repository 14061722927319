.p_modal_base {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .2);
    width: 100%;
    overflow: hidden;
    z-index: 5;
}

.p_modal_cont {
    position: relative;
    width: 70%;
    height: 78%;
    padding: 30px;
    border-radius: 20px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;
    cursor: default;
    -webkit-animation: fadeinout .15s linear forwards;
    animation: fadeinout .15s linear forwards;
}

.p_modal_scrollable {
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    max-height: 100%;
}

.p_modal_close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
    transition: all ease .1s;
}

.p_modal_close:hover {
    background-color: #f5f5f5;
}

.p_modal_close_icon {
    font-size: 22px;
    color: #333;
}


.p_modal_scrollable::-webkit-scrollbar {
    width: 0px;
}

.p_modal_img_cont {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 50%;
}

.p_modal_flex_imgs {
    display: flex;
    gap: 16px
}

.pm_img_two {
    position: relative;
}

.p_modal_desc_cont {
    width: 50%;
}

.p_modal_img_cont+.p_modal_desc_cont {
    margin-left: 30px;
}

.p_modal_heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.p_modal_flex_desc {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.p_modal_amountcnt {
    padding-right: 16px;
    border-right: 1px solid #d3d3d3;
}

.p_modal_amount {
    border-radius: 8px;
    border: 2px solid #22C55E;
    padding: 3px 6px;
    font-size: 16px;
    font-weight: 600;
    height: fit-content;
    color: #22C55E;
}

.p_modal_rating {
    display: flex;
    color: #666666;
    font-size: 14px;
    align-items: center;
    gap: 4px;
    margin-left: 16px;
}

.p_modal_rating span {
    display: block;
}

.p_modal_rating label {
    padding-left: 3px;
    padding-right: 2px;
    margin-right: 4px;
    font-size: 14px;
}

.p_modal_product_type {
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    z-index: 1;
}

.p_modal_designs {
    margin-top: 20px;
}

.p_modal_designs_heading {
    font-size: 14px;
    font-weight: 400;
}

.p_modal_designs_heading span {
    font-weight: 600;
}

.p_modal_designs_cont {
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.p_modal_design_base {
    border: 2px solid #999;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    cursor: pointer;
    transition: all .15s;
}

.p_modal_design_base.active {
    border: 2.5px solid #222;
}

.p_modal_design_img {
    width: 100%;
    border-radius: 50%;
}

.p_modal_counter {
    margin-top: 20px;
    border-radius: 30px;
    background-color: #F5F8FB;
    padding: 14px;
    width: fit-content;
}

.p_modal_button_cont {
    margin-top: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dedede;
}

.p_modal_toggle_container {
    margin-top: 20px;
}

@media screen and (max-width: 1280px) {
    .p_modal_cont {
        height: 100%;
        width: 80%;
    }
}

@media screen and (max-width: 1200px) {
    .p_modal_cont {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .p_modal_scrollable {
        flex-direction: column;
    }

    .p_modal_img_cont {
        width: 100%;
    }

    .p_modal_flex_imgs {
        display: none;
    }

    .p_modal_desc_cont {
        width: 100%;
    }
    .p_modal_img_cont+.p_modal_desc_cont {
        margin-left: 0px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 560px) {
    .p_modal_flex_desc {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
    }
    .p_modal_amountcnt {
        border: none;
        padding-right: none;
    }
    .p_modal_rating {
        margin-left: 0;
        padding-top: 10px;
    }
}